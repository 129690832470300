import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateEvent, fetchAreas, fetchEvents } from "../../services/";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const EditEvent = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery("info", () => fetchEvents({ id: id }), {
    select: (data) => data.events[0],
    refetchOnWindowFocus: false,
  });

  const { data: areas, isLoading: isAreasLoading } = useQuery(
    "areas",
    fetchAreas,
    {
      select: (data) =>
        data.areas.map((a) => {
          return { label: a.name, value: a.id };
        }),
      refetchOnWindowFocus: false,
    }
  );

  const saveChanges = async (values) => {
    try {
      await updateEvent(values, id);
      alert("Event has been successfully updated");
      window.location.reload();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
    }
  };

  if (isLoading || isAreasLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit <i className="text-muted">"{data?.title}"</i></h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/events">Events</a>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">Event info</h3>
                  </div>

                  <Formik
                    initialValues={{
                      title: data?.title,
                      description: data?.description,
                      photo: data?.photo,
                      start_date: data?.raw_start_date || "",
                      end_date: data?.raw_end_date || "",
                      n_tickets: data?.n_tickets,
                      price: data?.price,
                      map_link: data?.map_link,
                      area_id: data?.area_id,
                      playdate_type: data?.playdate_type,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveChanges(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string().required(),
                      description: Yup.string().required(),
                      photo: Yup.mixed().required(),
                      start_date: Yup.date().required(),
                      end_date: Yup.date()
                        .min(
                          Yup.ref("start_date"),
                          "An event end date cannot be before start date"
                        )
                        .required(),
                      n_tickets: Yup.number().required(),
                      price: Yup.string().required(),
                      map_link: Yup.string().required(),
                      area_id: Yup.number().required(),
                      playdate_type: Yup.string().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label>Title</label>

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="title"
                                    placeholder="Event Title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                    className="form-control"
                                  />
                                  {errors.title && touched.title && (
                                    <span className="text-danger text-sm">
                                      {errors.title}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-4">
                                  <label>Price</label>

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="price"
                                    placeholder="Ticket price"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.price}
                                    className="form-control"
                                  />
                                  {errors.price && touched.price && (
                                    <span className="text-danger text-sm">
                                      {errors.price}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-4">
                                  <label>Photo</label>
                                  <br />
                                  <img src={`${values.photo}`} height="100" />
                                  <br /><br />
                                  <input type="file" name="photo" onChange={(e) => {
                                    setFieldValue("photo", e.currentTarget.files[0]);
                                  }} />

                                  {errors.photo && touched.photo && (
                                    <span className="text-danger text-sm">
                                      {errors.photo}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label>Area</label>
                                  <Select
                                    name="area_id"
                                    options={areas}
                                    onChange={({ value }) =>
                                      setFieldValue("area_id", value)
                                    }
                                    onBlur={handleBlur}
                                    defaultValue={areas.filter((a) => {
                                        return a.value === data.area_id
                                    })}
                                  />
                                  {errors.area_id && touched.area_id && (
                                    <span className="text-danger text-sm">
                                      {errors.area_id}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-5">
                                  <label>Map Link</label>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="map_link"
                                    placeholder="Paste Google Map Link"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.map_link}
                                    className="form-control"
                                  />
                                  {errors.map_link && touched.map_link && (
                                    <span className="text-danger text-sm">
                                      {errors.map_link}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-3">
                                  <label>No. of tickets</label>
                                  <input
                                    type="number"
                                    name="n_tickets"
                                    placeholder="No. of tickets available"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.n_tickets}
                                    className="form-control"
                                  />
                                  {errors.n_tickets && touched.n_tickets && (
                                    <span className="text-danger text-sm">
                                      {errors.n_tickets}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                className="form-control"
                                placeholder="Event description (Bio)"
                                name="description"
                                rows={5}
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.description && touched.description && (
                                <span className="text-danger text-sm">
                                  {errors.description}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-3">
                                  <label style={{ display: "block" }}>
                                    Start Date
                                  </label>

                                  <DatePicker
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="form-control"
                                    placeholder="Starts on"
                                    style={{ width: "100% !important" }}
                                    selected={new Date(values.start_date)}
                                    onChange={(date) => {
                                      setFieldValue("start_date", date);
                                    }}
                                  />
                                  {errors.start_date && touched.start_date && (
                                    <span className="text-danger text-sm">
                                      {errors.start_date}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-3">
                                  <label style={{ display: "block" }}>
                                    End Date
                                  </label>

                                  <DatePicker
                                    showTimeSelect
                                    dateFormat="Pp"
                                    selected={new Date(values.end_date)}
                                    className="form-control"
                                    placeholder="Ends on"
                                    onChange={(date) => {
                                      setFieldValue("end_date", date);
                                    }}
                                  />
                                  {errors.end_date && touched.end_date && (
                                    <span className="text-danger text-sm">
                                      {errors.end_date}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label style={{ display: "block" }}>
                                    Play Date Type
                                  </label>

                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="playdate_type"
                                    placeholder="Play Date Type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.playdate_type}
                                    className="form-control"
                                  />
                                  {errors.playdate_type &&
                                    touched.playdate_type && (
                                      <span className="text-danger text-sm">
                                        {errors.playdate_type}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn bg-gradient-warning"
                              disabled={isSubmitting}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
