import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchEvents = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    return request({
        url: `${BaseUrl}v1/events${queryStr}`,
    }, true)
}

export const updateEvent = async (data, eventId) => {
    return request({
        url: `${BaseUrl}v1/event/${eventId}`,
        method: "PUT",
        data
    }, true)
}

export const addEvent = async (data) => {
    return request({
        url: `${BaseUrl}v1/create-event`,
        method: "POST",
        data
    }, true)
}

export const duplicateEvent = async (data, eventId) => {
    return request({
        url: `${BaseUrl}v1/duplicate-event/${eventId}`,
        method: "POST",
        data
    }, true)
}

export const refundPlayer = async (eventId, playerId) => {
    return request({
        url: `${BaseUrl}v1/refund/${eventId}/${playerId}`,
        method: "PUT"
    }, true)
}

export const fetchVouchers = async (id=null) => {
    return request({
        url: `${BaseUrl}v1/vouchers${id ? `?id=${id}` : ``}`
    }, true)
}

export const updateVoucher = async (data, voucherId) => {
    return request({
        url: `${BaseUrl}v1/voucher/${voucherId}`,
        method: "PUT",
        data
    }, true)
}

export const addVoucher = async (data) => {
    return request({
        url: `${BaseUrl}v1/create-voucher`,
        method: "POST",
        data
    }, true)
}

export const deleteGalleryImage = async (file, event_id) => {
    return request({
        url: `${BaseUrl}v1/delete-gallery/${event_id}`,
        method: "POST",
        data: {
            file
        }
    }, true)
}

export const fetchGallery = async (event_id=null) => {
    return request({
        url: `${BaseUrl}v1/gallery${event_id ? `?event_id=${event_id}` : ``}`
    }, true)
}

export const uploadFiles = async (data, event_id) => {
    return request({
        url: `${BaseUrl}v1/upload/${event_id}`,
        method: "POST",
        data
    }, true)
}

export const fetchEventQuestions = async (eventId) => {
    return request({
        url: `${BaseUrl}v1/questions/${eventId}`
    }, true)
}

export const saveQuestions = async (data, eventId) => {
    return request({
        url: `${BaseUrl}v1/update-questions/${eventId}`,
        method: "POST",
        data
    }, true)
}